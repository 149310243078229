export function getCustomerDisplayName(customer: {
    firstName?: string | null;
    lastName?: string | null;
    company1?: string | null;
    company2?: string | null;
    legalForm?: CustomerTenantLegalForm | null;
}) {
    if (
        customer.legalForm &&
        customer.legalForm === CustomerTenantLegalForm.COMPANY
    ) {
        return !customer.company1 && !customer.company2
            ? '-'
            : `${customer.company1 || ''}${customer.company2 ? ' / ' + customer.company2 : ''}`;
    }
    return !customer.firstName && !customer.lastName
        ? '-'
        : `${customer.firstName || ''} ${customer.lastName || ''}`.trim();
}

export enum CustomerTenantLegalForm {
    PRIVATE = 'PRIVATE',
    COMPANY = 'COMPANY',
}
