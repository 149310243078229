import { Box } from '@mui/material';

import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import {
    RequestStatus,
    ServerRequestState,
} from 'dg-web-shared/lib/hooks/ServerStateHooks';
import { ParkingaboButton } from './ParkingaboButton';

export function CancelSave<DT, ET>({
    onCancel,
    requestState,
    saveDisabled,
    loading,
}: {
    onCancel?: () => void;
    requestState: ServerRequestState<DT, ET>;
    saveDisabled?: boolean;
    loading?: boolean;
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: onCancel ? '100%' : '50%',
                marginLeft: 'auto',
            }}
        >
            {onCancel && (
                <ParkingaboButton
                    variant="outlined"
                    onClick={onCancel}
                    fullWidth
                    sx={{ marginRight: 1 }}
                >
                    <Localized
                        de="Abbrechen"
                        fr="Annuler"
                        it="Annulla"
                        en="Cancel"
                    />
                </ParkingaboButton>
            )}

            <ParkingaboButton
                variant="contained"
                type="submit"
                color="primary"
                sx={{ marginLeft: 1 }}
                disabled={saveDisabled}
                loading={
                    requestState.status === RequestStatus.PENDING || loading
                }
                fullWidth
            >
                <Localized de="Speichern" fr="Save" it="Salva" en="Save" />
            </ParkingaboButton>
        </Box>
    );
}
